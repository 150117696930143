#tips {
  position: relative;
  width: 100%;
  height: 100%;
}
#tips .tipsDetails {
  color: #5A4B41;
  width: 100%;
  height: 100%;
}
#tips .tipsDetails .contentWrapper {
  width: 375rem;
  display: flex;
  flex-direction: column;
  /* padding: 0 25rem; */
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
#tips .tipsDetails .header {
  font-size: 36rem;
  font-weight: 700;
}
#tips .tipsDetails .seperator {
  padding: 5rem 0 30rem;
}
#tips .tipsDetails .details {
  width: 100%;
  font-size: 18rem;
  padding: 0 35rem;
  text-align: center;
  overflow: hidden;
}
#tips .tipsDetails .details span {
  color: #ED6658;
}
#tips .tipsImage {
  margin-top: 25rem;
}
#tips .nextStageButton {
  width: 160rem;
  color: #FFFFFF;
  position: absolute;
  bottom: 50rem;
  left: 50%;
  transform: translateX(-50%);
}




#tips .arriveList {
  display: flex;
  position: absolute;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
}
#tips .characterImage.arrivedCharacter {
  border-radius: 50%;
  overflow: hidden;
  width: 35rem;
  height: 35rem;
  background-color: #c1c1c1;
  margin: 0 2rem;
}
#tips .characterImage.arrivedCharacter.eagle img {
  width: 190%;
  margin-left: -35%;
}
#tips .characterImage.arrivedCharacter.cat img {
  width: 160%;
  margin-left: -20%;
  margin-top: 10%;
}
#tips .characterImage.arrivedCharacter.squirrel img {
  width: 160%;
  margin-left: -15%;
  margin-top: -70%;
}
#tips .characterImage.arrivedCharacter.fish img {
  width: 160%;
  margin-left: -20%;
}