#landing {
  position: relative;
  width: 100%;
  height: calc(100% - 18vw);
}
#landing #logo {
  width: 100%;
  height: auto;
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 2;
}
#landing .appVersion {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16rem;
  padding: 5rem 10rem;
  color: #FFFFFF;
  font-family: CaptureIt, NotoSans, sans-serif;
  letter-spacing: 0.1em;
}
  #landing .transferButton {
  width: 36rem;
  height: 36rem;
  position: absolute;
  top: 4rem;
  left: 4rem;
  background-color: #efefe8;
  color: #5A4B41;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#landing .accountTransferPaper {
  position: absolute;
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#landing .accountTransferPaper img {
  width: 100%;
}
#landing .accountTransferPaper .content {
  position: absolute;
  top: 20rem;
  left: 10rem;
  right: 10rem;
  bottom: 20rem;
  text-align: center;
}
#landing .accountTransferPaper .content .header {
  color: #5A4B41;
  font-size: 20rem;
}
#landing .accountTransferPaper .content .accountData {
  margin: 30rem 0;
  color: #5A4B41;
}
#landing .accountTransferPaper .content .accountData .row {
  margin: 15rem 0;
  font-size: 16rem;
  display: block;
}
#landing .accountTransferPaper .content .gameCode {
  font-family: CaptureIt, NotoSans, sans-serif;
  display: inline-block;
  font-size: 20rem;
  width: 160rem;
  letter-spacing: 0.15em;
  vertical-align: middle;
}
#landing .accountTransferPaper .content .character,
#landing .accountTransferPaper .content .character .characterImage,
#landing .accountTransferPaper .content .label {
  display: inline-block;
  vertical-align: middle;
  width: 160rem;
}
#landing .accountTransferPaper .content .character .characterImage img {
  width: 80rem;
}
#landing .accountTransferPaper .content .row .deleteAccount {
  width: 50%;
  color: #FFFFFF;
  margin: 0 auto;
}
#landing .accountTransferPaper .content .accountData .row input {
  appearance: none;
  border: 0;
  background-color: transparent;
  font-family: CaptureIt, NotoSans, sans-serif;
  font-size: 20rem;
  color: #5A4B41;
  width: 160rem;
  letter-spacing: 0.15em;
  outline: 0;
  caret-color: transparent;
  color: transparent;
  position: absolute;
  text-transform: uppercase;
}
#landing .accountTransferPaper .content .accountData.new .row {
  margin: 30rem 0;
  position: relative;
}
#landing .accountTransferPaper .content .accountData.new .row .label {
  letter-spacing: 0.15em;
  font-family: CaptureIt, NotoSans, sans-serif;
  font-size: 20rem;
  pointer-events: none;
  text-transform: uppercase;
}
#landing .accountTransferPaper .content .accountData.new .character {
  display: inline-flex;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage {
  border-radius: 50%;
  overflow: hidden;
  width: 35rem;
  height: 35rem;
  background-color: #c1c1c1;
  margin: 0 2rem;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage.selected {
  background-color: #ED6658;
  border: 2rem solid #ED6658;
  width: 37rem;
  height: 37rem;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage.eagle img {
  width: 190%;
  margin-left: -35%;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage.cat img {
  width: 160%;
  margin-left: -20%;
  margin-top: 10%;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage.squirrel img {
  width: 160%;
  margin-left: -15%;
  margin-top: -70%;
}
#landing .accountTransferPaper .content .accountData.new .character .characterImage.fish img {
  width: 160%;
  margin-left: -20%;
}
#landing .buttonContainer {
  position: absolute;
  padding: 100rem 32.5rem 30rem;
  bottom: 30rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(0DEG, #5A4B41FF 50%, #5A4B4100);
}
#landing .startButton,
#landing .processButton {
  position: relative;
  /* bottom: 30rem; */
  left: 50%;
  width: 310rem;
  transform: translateX(-50%);
}
#landing .startButton.withProcessButton {
  /* bottom: 100rem; */
  margin-bottom: 15rem;
}
#landing .startButton .text,
#landing .processButton .text {
  font-family: CaptureIt, NotoSans, sans-serif;
  font-weight: 700;
  color: #FFFFFF;
}
#landing .startButton .text input {
  font-size: 20rem;
  appearance: none;
  border: 0;
  outline: 0;
  background-color: transparent;
  text-align: center;
  caret-color: transparent;
  letter-spacing: 0.1em;
  color: transparent;
}
#landing .startButton .text,
#landing .startButton .text .label {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  letter-spacing: 0.15em;
  /* pointer-events: none; */
  text-transform: uppercase;
  color: #5A4B41;
}
#landing .startButton .text .label span {
  display: inline-block;
  width: 1em;
  text-align: center;
}
#landing .startButton .text input:focus + .label,
#landing .startButton .text input:not(:placeholder-shown) + .label {
  font-weight: 400;
}
#landing .buttonContainer .errorMessage {
  color: #FFFFFF;
  padding-bottom: 10rem;
  text-align: center;
  font-size: 16rem;
  
}
#landing .startButton .text .codeWrong {
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
  width: 30rem;
  opacity: 0;
}
#landing .startButton .text .codeWrong img {
  vertical-align: top;
  width: 100%;
}
#landing .startButton.hasError .text .codeWrong {
  opacity: 1;
}
#landing .startButton.hasError .text .label {
  color: #ED6658;
}