#cooperateGame {
  position: relative;
  width: 100%;
  height: 100%;
}
#cooperateGame .gameStage {
  color: #5A4B41;
  width: 100%;
  height: 100%;
}
#cooperateGame .gameStage .contentWrapper {
  width: 375rem;
  display: flex;
  flex-direction: column;
  /* padding: 0 25rem; */
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
#cooperateGame .gameStage .header {
  font-size: 36rem;
  font-weight: 700;
}
#cooperateGame .gameStage .seperator {
  padding: 5rem 0 30rem;
}
#cooperateGame .gameStage .details {
  width: 100%;
  font-size: 18rem;
  padding: 0 35rem;
  text-align: center;
  overflow: hidden;
}
#cooperateGame .gameStage .details span {
  color: #ED6658;
}
#cooperateGame .showIntroButton {
  width: 160rem;
  color: #FFFFFF;
  position: absolute;
  bottom: 50rem;
  left: 50%;
  transform: translateX(-50%);
}
#cooperateGame .gameStage .details .swiper-wrapper {
  display: flex;
}
#cooperateGame .gameStage .details .swiper-wrapper .swiper-slide {
  flex: 0 0 100%;
  padding: 0 5rem;
}
#cooperateGame .gameStage .swiper-pagination {
  position: absolute;
  bottom: 50rem;
  display: flex;
}
#cooperateGame .gameStage .swiper-pagination .swiper-pagination-bullet {
  width: 10rem;
  height: 10rem;
  background-color: #bda16f;
  display: inline-block;
  border-radius: 50%;
  margin: 15rem;
}
#cooperateGame .gameStage .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #5a4b41;
}
#cooperateGame .gameStage .details .swiper-wrapper .swiper-slide .nextSlide,
#cooperateGame .gameStage .details .swiper-wrapper .swiper-slide .initGameButton {
  margin-top: 50rem;
}
#cooperateGame .gameStage .details .swiper-wrapper .swiper-slide .initGameButton {
  color: #FFFFFF;
  width: 160rem;
  margin: 50rem auto 0;
}

#cooperateGame .gameIndicators {
  position: absolute;
  top: 30rem;
  width: 100%;
  height: 2rem;
  background-color: rgb(176, 169, 161);
}


#cooperateGame .gameIndicator {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #B99D7E;
  color: #FFFFFF;
  width: 30rem;
  height: 30rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14rem;
  font-family: CaptureIt;
  z-index: 1;
}
#cooperateGame .gameIndicator.active {
  background-color: #5A4B41;
}
#cooperateGame .gameIndicator.game1 {
  transform: translate(-97.5rem,-50%);
  background-color: #5A4B41;
}
#cooperateGame .gameIndicator.game2 {
  transform: translate(-42.5rem,-50%);
}
#cooperateGame .gameStage3 .gameIndicator.game2,
#cooperateGame .gameStage4 .gameIndicator.game2,
#cooperateGame .gameStage5 .gameIndicator.game2 {
  background-color: #5A4B41;
}
#cooperateGame .gameIndicator.game3 {
  transform: translate(12.5rem,-50%);
}
#cooperateGame .gameStage4 .gameIndicator.game3,
#cooperateGame .gameStage5 .gameIndicator.game3 {
  background-color: #5A4B41;
}
#cooperateGame .gameIndicator.game4 {
  transform: translate(67.5rem,-50%);
}
#cooperateGame .gameStage5 .gameIndicator.game4 {
  background-color: #5A4B41;
}
#cooperateGame .gameIndicators .progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #5A4B41;
}
#cooperateGame .gameStage2 .gameIndicators .progress {
  width: calc(50% - 85rem);
}
#cooperateGame .gameStage3 .gameIndicators .progress {
  width: calc(50% - 30rem);
}
#cooperateGame .gameStage4 .gameIndicators .progress {
  width: calc(50% + 25rem);
}
#cooperateGame .gameStage5 .gameIndicators .progress {
  width: calc(50% + 80rem);
}
#cooperateGame .gameStage .timer {
  position: absolute;
  top: 75rem;
  text-align: center;
  z-index: 1;
}
#cooperateGame .gameStage .timer .time {
  font-family: CaptureIt;
  font-size: 64rem;
  line-height: 64rem;
}
#cooperateGame .gameStage .timer .time span {
  display: inline-block;
  width: 35rem;
}
#cooperateGame .gameStage .timer .time span.char_\. {
  width: 20rem;
}
#cooperateGame .gameStage .role {
  position: absolute;
  top: 170rem;
  text-align: center;
  z-index: 1;
  font-size: 18rem;
  background-color: #5A4B41;
  padding: 5rem 15rem;
  color: #FFFFFF;
  display: inline-block;
  border-radius: 50rem;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
#cooperateGame .gameStage .paper,
#cooperateGame .gameStage .paper img {
  position: relative;
  width: 375rem;
}
#cooperateGame .gameStage .paper {
  position: absolute;
  top: 165rem;
  bottom: 85rem;
}
#cooperateGame .gameStage .paper img {
  height: 100%;

}
#cooperateGame .gameStage .paper .text {
  position: absolute;
  top: 35rem;
  left: 35rem;
  right: 35rem;
  bottom: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#cooperateGame .gameStage .paper .text .role {
  top: 20rem;
}
#cooperateGame .gameStage .paper .text .actions .action {
  font-size: 28rem;
  margin-bottom: 20rem;
}
#cooperateGame .gameStage .paper .text .actions .actionDetails {
  padding: 0 15rem;
}
#cooperateGame .gameStage .paper .text .actions .actionDetails span {
  color: #ED6658;
}
#cooperateGame .svgButton.announced {
  color: #FFFFFF;
  position: absolute;
  bottom: 20rem;
  width: 310rem;
}
#cooperateGame .commanderHints {
  position: absolute;
  bottom: 75rem;
  color: #5A4B41;
  width: 300rem;
  font-size: 18rem;
  text-align: center;
}
#cooperateGame .gameStage.gameStageFailed .statusIcon,
#cooperateGame .gameStage.gameStageWin .statusIcon  {
  margin-top: 50rem;
  width: 230rem;
  height: 230rem;
  text-align: center;
}

#cooperateGame .gameStage.gameStageFailed .livesRemain {
  position: absolute;
  top: 75rem;
  text-align: center;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives {
  position: relative;
  margin-top: 10rem;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives .allLives {
  display: flex;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives .currentLives {
  display: flex;
  position: absolute;
  top: 0;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives .lifeIcon {
  margin: 0 5rem;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives .lifeIcon img {
  vertical-align: top;
}
#cooperateGame .gameStage.gameStageFailed .livesRemain .lives .currentLives .lifeIcon:last-child {
  animation: loseLife 0.6s 0.3s forwards;
}
#cooperateGame .gameStage.gameStageFailed .reason,
#cooperateGame .gameStage.gameStageWin .reason {
  padding-top: 20rem;
  width: 230rem;
  text-align: center;
}
/* #cooperateGame .gameStage.gameStageWin .reason {
  font-weight: 700;
} */
#cooperateGame .gameStage.gameStageFailed .reason .step,
#cooperateGame .gameStage.gameStageWin .reason .step {
  font-weight: 700;
  margin-bottom: 5rem;
  font-size: 21rem;
}
#cooperateGame .gameStage.gameStageFailed .reason .step span,
#cooperateGame .gameStage.gameStageFailed .reason .details span {
  color: #ED6658;  
}
#cooperateGame .gameStage.gameStageFailed .reason .details,
#cooperateGame .gameStage.gameStageWin .reason .details {
  line-height: 1.5;
  padding: 0 10rem;
}
#cooperateGame .gameStage.gameStageFailed .playAgainButton {
  position: absolute;
  bottom: 40rem;
  width: 160rem;
  left: 50%;
  transform: translateX(-50%);
  color: #FFFFFF;
}
#cooperateGame .gameStage.gameStageWin {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#cooperateGame .debugProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  RIGHT: 0;
  padding: 5rem 10rem;
  font-size: 10rem;
  display: flex;
  justify-content: space-between;
}

#cooperateGame .arriveList {
  display: flex;
  margin-top: 10rem;
  justify-content: center;
}
#cooperateGame .gameStageFailed .arriveList {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
#cooperateGame .characterImage.arrivedCharacter {
  border-radius: 50%;
  overflow: hidden;
  width: 35rem;
  height: 35rem;
  background-color: #c1c1c1;
  margin: 0 2rem;
}
#cooperateGame .characterImage.arrivedCharacter.eagle img {
  width: 190%;
  margin-left: -35%;
}
#cooperateGame .characterImage.arrivedCharacter.cat img {
  width: 160%;
  margin-left: -20%;
  margin-top: 10%;
}
#cooperateGame .characterImage.arrivedCharacter.squirrel img {
  width: 160%;
  margin-left: -15%;
  margin-top: -70%;
}
#cooperateGame .characterImage.arrivedCharacter.fish img {
  width: 160%;
  margin-left: -20%;
}
@media screen and (max-width: 320px) { 
  #cooperateGame .gameStage .paper,
  #cooperateGame .gameStage .paper img {
    width: 350rem;
  }
  #cooperateGame .gameStage.gameStageFailed .statusIcon,
  #cooperateGame .gameStage.gameStageWin .statusIcon {
    width: 200rem;
    height: 200rem;
  }
  #cooperateGame .gameStage.gameStageFailed .statusIcon img,
  #cooperateGame .gameStage.gameStageWin .statusIcon img {
    width: 200rem;
    height: 200rem;
  }
}

@keyframes loseLife {
  0% {
    transform: scale(1);
    /* opacity: 1; */
  }
  50% {
    transform: scale(1.4);
    /* filter: saturate(1); */
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
    /* filter: saturate(0); */
  }
}