.svgTalkBox {
  position: relative;
  z-index: 1;
}
.svgTalkBox svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}
.svgTalkBox .content {
  position: relative;
  z-index: 1;
}