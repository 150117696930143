#preloadResource {
  position: absolute;
  top: 0;
  bottom: 18vw;
  left: 0;
  right: 0;
  z-index: 1001;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: #4a5247;
}
#preloadResource img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top center;
}
#preloadResource #logo {
  width: 100%;
  height: auto;
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 2;
}
#preloadResource .loaded {
  position: fixed;
  bottom: 80rem;
  width: 100%;
  text-align: center;
  font-family: CaptureIt, NotoSans, sans-serif;
  color: #FFFFFF;
}
#preloadResource .loaded .loadingBar {
  height: 5rem;
  background-color: #BB9853;
}
#preloadResource .debugButton {
  position: absolute;
  right: 10rem;
  bottom: 10rem;
}
@media screen and (orientation: landscape) {
  
}
@media screen and (max-width:380px) {

}