#debugPanel {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 100%;
  width: 50%;
  z-index: 100;
  background-color: #f8f8f8;
  transition: all 0.4s;
}
#debugPanel.show {
  left: 50%;
}
#debugPanel .toggleButton {
  position: absolute;
  right: 100%;
  top: 20rem;
  margin-right: 20rem;
  width: 60rem;
  border-radius: 10rem;
  overflow: hidden;
  opacity: 0.2;
  transition: all 0.4s;
}
#debugPanel .panelBackground {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.2);
  z-index: 0;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
}
#debugPanel.show .panelBackground {
  visibility: visible;
  opacity: 1;
}
#debugPanel.show .toggleButton {
  opacity: 0.8;
}
#debugPanel .toggleButton img {
  width: 100%;
  vertical-align: top;
}
#debugPanel .scrollWrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  position: relative;
  z-index: 1;
}
#debugPanel .characterWrapper .characterImage img {
  width: 100%;
}
#debugPanel .characterWrapper {
  display: flex;
  flex-wrap: wrap;
  /* background-color: tomato; */
  height: auto;
  /* width: 50%; */
  padding: 25rem;
  gap: 20rem;
  justify-content: center;
  z-index: 1;
  position: relative;
  align-items: center;
}
#debugPanel .characterWrapper .characterImage {
  flex: 1 0 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#debugPanel .characterWrapper .characterImage.selected {
  border: 1px solid #000000;
}
#debugPanel .sceneWrapper {
  position: relative;
  z-index: 1;
  padding: 10rem;
}
#debugPanel .sceneWrapper select {
  width: 100%;
  padding: 10rem;
}
#debugPanel .sceneWrapper a {
  display: block;
  text-align: center;
  margin: 15rem 0;
  padding: 5rem 0;
  text-decoration: none;
  background-color: #ececec;
  border-radius: 5rem;
}

@media screen and (orientation: landscape) {
  #debugPanel .toggleButton {
    width: 25rem;
  }
  #debugPanel .characterWrapper {
    gap: 0;
    justify-content: space-between;
    padding: 20rem 10rem;
  }
  #debugPanel .characterWrapper .characterImage {
    flex: 0 0 20%;
  }
}