#rotateGame {
  position: relative;
  padding-top: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #ccc;
}
#rotateGame .finalReading,
#rotateGame .currentReading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}
#rotateGame .finalReading:after,
#rotateGame .currentReading:after {
  content: "";
  position: absolute;
  bottom: 50%;
  left: 50%;
}
#rotateGame .finalReading:after {
  border-top: 100px solid #248f24;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  margin-left: -20px;
}
#rotateGame .currentReading:after {
  border-bottom: 85px solid hsl(0, 0%, 0%);
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  margin-left: -3px;
}
#rotateGame .finalReading.complete {
  background-color: #248f24;
}