.svgButton {
  position: relative;
}
.svgButton svg {
  width: 100%;
  vertical-align: top;
}
.svgButton.active {
  filter: brightness(0.9);
}
.svgButton .text {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: 700;
}