.hide{
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

#vr360 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tutor.show{
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.tutor.show .contentWrapper{
  padding: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.tutor.show .contentWrapper .head{
  font-size: 32rem;
  color: #5A4B41;
  font-weight: 700;
}
.tutor.show .contentWrapper .content{
  font-size: 18rem;
  color: #5A4B41;
  text-align: center;
  /* margin-bottom: 70rem; */
  margin-bottom: 56rem;
  margin-top: 34rem;
}
.tutor.show .contentWrapper .foodImage{
  margin: 25rem 0rem 25rem 0rem;
  width: 45%;
  display: flex;
  justify-content: center;
}
.tutor.show .contentWrapper .nextStageButton{
  color: white;
  width: 157rem;
}
.tutor.show .dotsWrapper{
  position: absolute;
  bottom: 67rem;
  left: 155rem;
  width: 75rem;
  height: 5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.tutor.show .dotsWrapper .dot{
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: #5A4B41;
}
.tutor.show .dotsWrapper .dot.fade{
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: #5a4b4134;
}

#vr360 .vrTalkBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200rem;
}

#vr360 .vrTalkBox .content {
  padding: 25rem 0 25rem 25rem;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 375rem;
  margin: 0 auto;
}
#vr360 .vrTalkBox .content .text {
  flex: 0 0 200rem;
  font-weight: 500;
  margin-right: auto;
  position: relative;
  z-index: 1;
  background-color: rgba(239, 239, 232, 0.5);
  padding: 10rem 0;
  box-shadow: 0 0 25rem #efefe8;
}
#vr360 .vrTalkBox .content .foodImage {
  padding-right: 25rem;
  margin-left: auto;
}
#vr360 .vrTalkBox .content .foodImage img {
  /* width: 100%; */
  width: 80rem;
}
#vr360 .vrTalkBox .content .image.character {
  flex: 0 0 150rem;
  margin-left: auto;
  height: 200rem;
  margin-right: 0rem;
  margin-bottom: -25rem;
  align-self: flex-end;
  position: relative;
}
#vr360 .vrTalkBox .content .image.image.character img {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 70%;
  transform: translateX(-50%);
}

.finished.show{
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
.finished.show .contentWrapper{
  padding: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}
.finished.show .contentWrapper .head{
  font-size: 36rem;
  color: #5A4B41;
  font-weight: 700;
}
.finished.show .contentWrapper .content{
  font-size: 18rem;
  color: #5A4B41;
  text-align: center;
  margin-bottom: 30rem;

}
.finished.show .contentWrapper .foodImage{
  margin: 25rem 0rem 25rem 0rem;
}

.finished.show .contentWrapper .nextStageButton{
  color: white;
  width: 157rem;
}