@font-face {
  font-family: PressStart;
  src: url(./media/fonts/PressStart2P-Regular.ttf);
}
@font-face {
  font-family: SpaceGrotesk;
  src: url(./media/fonts/SpaceGrotesk-Bold.ttf);
}
@font-face {
  font-family: NotoSans;
  src: url(./media/fonts/NotoSansHK-Regular.otf);
}
@font-face {
  font-family: 'CaptureIt';
  src: url(./media/fonts/Capture_it.woff) format('woff');
  }
* {
  box-sizing: border-box;
  touch-action: manipulation;
  -webkit-touch-callout: none;
  /* user-select: none;   */
  image-rendering: crisp-edges;
}
html {
  font-size: -webkit-calc(100vw / 768);
  font-size: -moz-calc(100vw / 768);
  font-size: calc(100vw / 768);
  background-color: #000000;
}
html, body {
  width: 100%;
  height: 100%;
  height: -webkit-fill-available;
  margin: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: auto;
}
body {
  font-size: 20rem;
  line-height: 1.4;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-family: NotoSans, sans-serif;
}
#root {
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 100%;
  
  transform: translateX(-50%);
  /* overflow-x: hidden; */
  overflow: hidden;
  /* width: 100vw;
  height: 100vh; */
  -webkit-overflow-scrolling: touch;
}
.backgroundWrapper {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.backgroundWrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

.hide {
  visibility: hidden;
  opacity: 0;
}
.screenWrapper {
  width: 375rem;
}
@media screen and (orientation: landscape) and (min-width: 560px) {
  /* html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  } */
}
@media screen and (orientation: landscape) and (max-height: 767px) {
  html {
    font-size: -webkit-calc(100vh / 375);
    font-size: -moz-calc(100vh / 375);
    font-size: calc(100vh / 375);
  }
}
@media screen and (max-width: 767px) {
  html {
    font-size: -webkit-calc(100vw / 375);
    font-size: -moz-calc(100vw / 375);
    font-size: calc(100vw / 375);
  }
}

/* @media screen and (min-aspect-ratio: 375/667) {
  html {
    font-size: calc(56.25 * var(--vh, 1vh) / 375);
  }
} */