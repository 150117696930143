#backpack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 100;
}
#backpack .backgroundWrapper,
#story #backpack .backgroundWrapper {
  bottom: 0;
}
#backpack .topPanel {
  color: #5A4B41;
  font-size: 28rem;
  height: 160rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#backpack .topPanel > svg {
  transform: rotate(180DEG);
  top: auto;
  bottom: 0;
}
#backpack .topPanel .itemHints {
  position: absolute;
  bottom: 15rem;
  color: #F1F1EB;
  font-size: 14rem;
  width: 225rem;
  height: 25rem;
}
#backpack .topPanel .itemHints svg {
  height: 100%;
}
#backpack .topPanel .itemHints .text {
}
#backpack .backpackContent {
  position: absolute;
  top: 150rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 20rem;
}
#backpack .verticalDivider {
  position: absolute;
  left: 50%;
  top: 140rem;
  bottom: 0;
  transform: translateX(-50%);
}
#backpack .verticalDivider img {
  width: auto;
  height: 100%;
}
#backpack .backpackContent .item {
  width: 50%;
  padding-top: 50%;
  position: relative;
  display: inline-block;
}
#backpack .backpackContent .item .itemWrapper {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 90%;
}
#backpack .backpackContent .item .itemWrapper .uiImage,
#backpack .backpackContent .item .itemWrapper .foodImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#backpack .backpackContent .item .itemWrapper .foodImage.ready img,
#backpack .backpackContent .item .itemWrapper .uiImage img {
  width: 100%;
}

#backpack .backpackContent .dividerBrown {
  height: 10rem;

}
#backpack .backpackContent .dividerBrown img {
  vertical-align: top;
  width: 100%;
}
#backpack .topPanel .backButton {
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translateY(-50%) rotate(180DEG);
}
#backpack .topPanel .backpackIcon {
  position: absolute;
  top: 50%;
  right: 20rem;
  transform: translateY(-50%);
}
#backpack .topPanel .backpackIcon img {
  vertical-align: top;
}
#backpack .topPanel > .text {
  position: relative;
  z-index: 1;
  text-align: center;
}
#backpack .topPanel .text .foundCount {
  font-size: 18rem;
} 
#backpack .topPanel .dividerBrown {
  position: absolute;
  bottom: 0;
  left: 20rem;
}
#backpack .topPanel .dividerBrown img {
  vertical-align: bottom;
}

#backpack .backpackDetails {
  position: absolute;
  top: 140rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 50rem 30rem;
  font-size: 19rem;
}
#backpack .backpackDetails .itemImage {
  text-align: center;
  margin-bottom: 10rem;
}
#backpack .backpackDetails .itemImage img {
  width: 80%;
}
#backpack .backpackDetails .itemImage.foodImage {
  width: 60%;
  padding-top: 60%;
  margin: 0 auto 20rem;
  position: relative;
}
#backpack .backpackDetails .itemImage.foodImage img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}