#test {
  min-height: 100%;
  background-color: #ecf0f5;
  padding: 15px;
}
#test label {
  font-size: 0.8em;
  font-weight: bold;
  display: block;
}
#test .accordion {
  background-color: #FFFFFF;
  border-radius: 5px;
}
#test .accordionItem {
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  opacity: 0.5;
}
#test .accordionItem.active {
  opacity: 1;
}
#test .accordionItem .accordionHeader {
  border-bottom: 1px solid #f4f4f4;
  padding: 10px 15px 10px 15px;
  position: relative;
  font-weight: bold;
}
#test .accordionItem.active .accordionHeader::before {
  content: "";
  position: absolute;
  border-left: 5px solid #000;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  left: 6px;
  top: 16px;
}
#test .accordionItem .accordionBody {
  padding: 10px 15px;
  display: none;
  position: relative;
}
#test .accordionItem.active .accordionBody {
  display: block;
}
#test .accordionItem.active .accordionBody video {
  width: 100%;
}

@media screen and (orientation: landscape) {
  #test {
    padding-left: max(15px, env(safe-area-inset-left, 20px));
    padding-right: max(15px, env(safe-area-inset-right, 20px));
  }
}
@media screen and (orientation: portrait) {
  #test {
    padding-top: max(15px, env(safe-area-inset-top, 20px));
    padding-bottom: max(15px, env(safe-area-inset-bottom, 20px));
  }
}