#flagGame {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  padding: 15rem 0 45rem;
}
#flagGame .flagImage {
  display: inline-flex;
  padding: 10rem;
  background-color: #FFFFFF;
  width: 175rem;
  height: 175rem;
  position: relative;
  justify-content: center;
  align-items: center;
}
#flagGame .flagImage > img {
  vertical-align: top;
  width: 155rem;
}
#flagGame .flagImage .fullscreenButton {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
}
#fullscreenFlag {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.4s;
}
#fullscreenFlag.ready {
  opacity: 1;
}
#fullscreenFlag .backButton {
  position: absolute;
  top: 25rem;
  left: 25rem;
  z-index: 2;
}
#fullscreenFlag .hints {
  position: absolute;
  bottom: 25rem;
  left: 50%;
  color: #FFFFFF;
  width: 250rem;
  transform: translateX(-50%);
  font-size: 16rem;
  text-align: center;
  /* padding: 20rem; */
}
#fullscreenFlag .flagImage {
  position: relative;
  z-index: 1;
}
