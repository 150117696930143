#cp3Game2 {
  position: relative;
  /* padding-top: 200px; */
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  background-color: #CCCCCC90;
}
#cp3Game2:before {
  content: '';
  position: absolute;
  left: 100%;
  height: 100%;
  width: 50rem;
  background-image: linear-gradient(90DEG, #CCCCCC90, #CCCCCC00);
}
#cp3Game2:after {
  content: '';
  position: absolute;
  right: 100%;
  height: 100%;
  width: 50rem;
  background-image: linear-gradient(-90DEG, #CCCCCC90, #CCCCCC00);
}
#cp3Game2 .gameToolsWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 250rem;
  top: 205rem;
  bottom: 120rem;
  margin: auto;
}
#cp3Game2 .gameToolsWrapper .gameToolSelector {
  flex: 0 0 33.33333333%;
  width: 100%;
  position: relative;
  z-index: 1;
  padding-right: 220rem;
  padding-left: 25rem;
  display: flex;
  align-items: center;
}
#cp3Game2 .gameToolsWrapper .gameToolSelector .toolsName {
  background-color: #F1F1EB;
  margin-right: 10rem;
  padding: 2rem 0rem;
  width: 80rem;
  text-align: center;
  border-radius: 30rem;
  color: #5A4B41;
  font-size: 18rem;
}
#cp3Game2 .cp3GameTools{
  height: 250rem;
  position: absolute;
  left: 50%;
  transform: translateX(-30rem);
}
#cp3Game2 .cp3GameTools img {
  height: 250rem;
  vertical-align: top;
}

#cp3Game2 .gameToolsWrapper .gameToolSelector.wrong {
  /* background-color: #FFFFFF; */
  mix-blend-mode: hue;
  animation: wrongTools 1s forwards;
}
#cp3Game2 .gameArea {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 250rem;
  top: 205rem;
  bottom: 120rem;
  margin: auto;
}
#cp3Game2 .gameArea .cannonMuzzle {
  width: 375rem;
  height: 250rem;
}
#cp3Game2 .gameArea .cannonMuzzle img {
  height: 250rem;
  display: block;
  margin: 0 auto;
}
#cp3Game2 .gameArea .tool {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg) translate(-20rem, -50rem);
  transform-origin: left center;
  transition: all 0.4s;
}
#cp3Game2 .gameArea .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
#cp3Game2 .gameArea .progress span {
  font-family: CaptureIt;
  font-size: 60rem;
}
#cp3Game2 .actionHints {
  position: absolute;
  bottom: 20rem;
  color: #FFF;
  width: 200rem;
  font-size: 18rem;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes wrongTools {
  0% {
    background-color: #FF0000;
  }
  100% {
    background-color: #FFFFFF;
  }
}