#artest {
  position: relative;
  display: inline-block;
  max-width: 100%;
  background-color: red;
  overflow: hidden;
}
#artest .loadingNFT {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
#artest video {
  width: 100%;
  display: block;
}