#gameTimer {
  position: fixed;
  top: 50rem;
  right: -5rem;
  color: #ffffff;
  font-size: 12px;
  text-align: center;
  z-index: 99;
}

#map #gameTimer .timeFlag img {
  visibility: hidden;
}
#gameTimer span {
  letter-spacing: 0.1em;
  font-size: 14px;
  font-family: CaptureIt, NotoSans, sans-serif;
}
#gameTimer .timeFlag .text {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  font-size: 12px;
}
#map #gameTimer .timeFlag .text {
  font-size: 12px;
}
@media screen and (max-width: 320px) {
  #gameTimer {
    top: 40rem;
  }
}