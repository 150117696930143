#cp3Game4 {
  position: relative;
  /* padding-top: 200px; */
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  background-color: #CCCCCC90;
}
#cp3Game4:before {
  content: '';
  position: absolute;
  left: 100%;
  height: 100%;
  width: 50rem;
  background-image: linear-gradient(90DEG, #CCCCCC90, #CCCCCC00);
}
#cp3Game4:after {
  content: '';
  position: absolute;
  right: 100%;
  height: 100%;
  width: 50rem;
  background-image: linear-gradient(-90DEG, #CCCCCC90, #CCCCCC00);
}

#cp3Game4 .gameArea {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 250rem;
  top: 205rem;
  bottom: 120rem;
  margin: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}
#cp3Game4 .gameArea .cannonMuzzle {
  width: 375rem;
  height: 250rem;
}
#cp3Game4 .gameArea .cannonMuzzle img {
  height: 250rem;
  display: block;
  margin: 0 auto;
}
#cp3Game4 .gameArea .tool {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg) translate(-20rem, -50rem);
  transform-origin: left center;
  transition: all 0.4s;
}
#cp3Game4 .gameArea .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
}
#cp3Game4 .gameArea .progress span {
  font-family: CaptureIt;
  font-size: 60rem;
}
#cp3Game4 .actionHints {
  position: absolute;
  bottom: 20rem;
  color: #FFF;
  width: 200rem;
  font-size: 18rem;
  left: 50%;
  transform: translateX(-50%);
}

#cp3Game4 .gameArea .arcPath {
  position: absolute;
  width: 250rem;
  height: 250rem;
}