#end {
  width: 100%;
  height: 100%;
}
#end .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}
#end .answerPaper {
  position: absolute;
  left: 30rem;
  right: 30rem;
  padding: 0 25rem;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 400rem;
}
#end .answerPaper > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#end .answerPaper .swiper-container {
  overflow: hidden;
  height: 100%;
}
#end .swiper-wrapper {
  display: flex;
  height: 100%;
}
#end .answerPaper .swiper-wrapper .swiper-slide {
  flex: 0 0 100%;
  padding: 35rem 5rem;
}

#end .thanks-pagination {
  position: absolute;
  top: 100%;
  display: flex;
  left: 50%;
  transform: translateX(-50%);
}
#end .thanks-pagination .swiper-pagination-bullet {
  width: 10rem;
  height: 10rem;
  background-color: #5a4b41;
  display: inline-block;
  border-radius: 50%;
  margin: 8rem;
}
#end .thanks-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #FFFFFF;
}
#end .answerPaper .contentWrapper {
  /* position: absolute;
  top: 0; */
}
#end .answerPaper .contentWrapper .header {
  color: #5A4B41;
  text-align: center;
  margin-bottom: 20rem;
  font-weight: 700;
  width: 245rem;
  font-size: 24rem;
  margin: 0 auto;
}
#end .answerPaper .contentWrapper .seperator img {
  width: 100%;
}
#end .answerPaper .contentWrapper .details {
  color: #5A4B41;
  text-align: center;
  margin: 30rem 0 60rem;
  font-size: 16rem;
}
#end .answerPaper .contentWrapper .label {
  color: #BB9853;
  display: inline-block;
}
#end .answerPaper .contentWrapper .thanks {
  font-size: 16rem;
  margin-top: 25rem;
}
#end .answerPaper .contentWrapper .timeUsed {
  font-size: 20rem;
}
#end .thanksIcon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#end .thanksIcon img {
  width: 80%;
}
#end .mapButton {
  color: #FFFFFF;
  width: 250rem;
  margin: 0 auto;
  position: absolute;
  bottom: 30rem;
  left: 0;
  right: 0;
}


#end .viewBackpackButton {
  position: absolute;
  top: 20rem;
  left: 20rem;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 16rem;
  color: #FFFFFF;
}
#end .viewHistoryButton {
  position: absolute;
  top: 90rem;
  left: 20rem;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 16rem;
  color: #FFFFFF;
}
#end .grades {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(25%);
  pointer-events: none;
}
#end .grades .gradeText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(15DEG)  scaleX(0.8) ;
  font-family: CaptureIt, NotoSans, sans-serif;
  font-size: 60rem;
  color: #D6B77A;
}
#end .mapWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}