#tutor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#tutor .backgroundWrapper img {
  object-position: top;
}

#tutor .tutor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25rem;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
#tutor .tutor {
}
#tutor .tutor .screenWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
#tutor .tutor .header {
  font-size: 36rem;
  margin-bottom: 5rem;
  font-weight: bold;
}
#tutor .tutor .separator,
#tutor .tutor .separator img {
  width: 100%;
}
#tutor .tutor .separator {
  margin-bottom: 30rem;
}
#tutor .tutor .brief {
  padding: 0 20rem;
  font-size: 20rem;
  margin-bottom: 50rem;
}
#tutor .tutor .nextStep {
  width: 160rem;
  margin: 0 auto;
  z-index: 1;
  /* position: absolute;
  bottom: 62rem;
  left: 109rem; */
}
#tutor .tutor .nextStep.notReady {
  filter: brightness(0.7);
}
#tutor .tutor .nextStep.start {
  width: 294rem;
  position: absolute;
  bottom: 74rem;
  left: 47rem;
}
#tutor .tutor.page2 .nextStep,
#tutor .tutor.page3 .nextStep,
#tutor .tutor.page4 .nextStep {
  width: 160rem;
  margin: 0 auto;
  position: absolute;
  bottom: 62rem;
  left: 109rem;
}
#tutor .tutor .tutorCharacterImage {
  position: absolute;
  bottom: 0;
}
#tutor .tutor .tutorCharacterImage img {
  width: 200rem;
  margin: 0rem 0rem 0rem 141rem;
}


#tutor .tutor.page2,
#tutor .tutor.page3 {
  background-color: rgba(0, 0, 0, 0);
}
#tutor .tutor.page2 .header{
  font-size: 28rem;
  margin-top: 110rem;
}
#tutor .tutor .brief.small{
  font-size: 17rem;
  line-height: 26rem;
  padding-bottom: 117rem;
}

#tutor .tutor.page4 .brief{
  padding: 0 13rem;
}

#tutor .iconWrapper{
  position: absolute;
  top: 24rem;
  left: 17rem;
} 
.iconWrapper .backpack img{
  margin-bottom: 10rem;
}
.tutor.page2 .arrow{
  position: absolute;
  left: 91rem;
  top: 30rem;
}

.tutor.page3 .arrow{
  position: absolute;
  left: 91rem;
  top: 112rem;
}

.tutor.page3 .header{
  margin-top: 20rem;
}

#tutor .tutor.page4 .separator{
  margin-bottom: 13rem;
}

.tutor.page4.tutor.page4 .checkBoxWrap{
  margin-left: 15rem;
  text-align: left;
}
.tutor.tutor.page4 .checkBoxWrap .box{
  margin-bottom: 15rem;
}
.tutor.page4 .uiImage.checkBox img{
  position: absolute;
  margin-top: 4rem;
}
.tutor.page4 .uiImage.tick img{
  position: absolute;
  margin-top: 3rem;
}
.tutor.page4 .uiImage.tick.hide{
  display: none;
}
.tutor.page4 .checkBoxWrap .text{
  /* position: absolute; */
  margin-left: 50rem;
}
.tutor.page4 .checkBoxWrap .text.dim{
  color: #ffffff59;
}
.tutor.page4 .p4CharacterImage img{
  position: absolute;
  width: 176rem;
  bottom: 5rem;
  right: -9rem;
}