#splash {
  position: absolute;
  top: 0;
  bottom: 18vw;
  left: 0;
  right: 0;
  z-index: 1001;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  background-color: #4a5247;
}
#splash img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top center;
}
#splash #logo {
  width: 100%;
  height: auto;
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 2;
}
#splash .permissions {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #FFFFFF; */
  border-radius: 15px;
  padding: 15rem;
}
#splash .permissions .uiImage {
  position: relative;
}
#splash .permissions .uiImage.checkBox,
#splash .permissions .uiImage.checkBox img {
  width: 20rem;
  height: 20rem;
  display: inline-block;
  position: relative;
  vertical-align: top;
}
#splash .permissions .uiImage.checkBox {
  margin-right: 10rem;
}
#splash .permissions .uiImage.checkBox .check {
  position: absolute;
  top: 0;
  left: 0;
}
#splash .permissions .uiImage.checkBox .check img {
  height: 100%;
  width: auto;
}
#splash .permissions .uiImage .text {
  color: #5A4B41;
  font-size: 16rem;
  position: absolute;
  padding: 50rem;
  top: 0;
  height: 100%;
}
#splash .permissions .uiImage .text .header {
  text-align: center;
  margin-bottom: 5rem;
}
#splash .permissions .uiImage .text .header.error {
  color: #DE7267;
}
#splash .permissions .uiImage .text .divider {
  margin-bottom: 15rem;
}
#splash .permissions .allowButton {
  width: 160rem;
  margin: 0 auto;
  color: #FFFFFF;
}
#splash .installHints {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(#5A4B4180, #5A4B41FF 75%);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-bottom: 30rem;
  padding-top: 39rem;
}
#splash .installHints .osSelectWrapper {
  position: absolute;
  top: 22vh;
  left: 50%;
  transform: translateX(-50%);
  width: 246rem;
  height: 27rem;
  background-color: #ffffff6b;
  border-radius: 10rem;
}
#splash .installHints .osSelectWrapper .ios{
  position: absolute;
  top: 0rem;
  left: 0rem;
  width: 95rem;
  height: 27rem;
  color: #5A4B41;
  font-size: 14rem;
  background-color: white;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
}
#splash .installHints .osSelectWrapper .ios.opaque{
  background-color: transparent;
}
#splash .installHints .osSelectWrapper .android{
  position: absolute;
  top: 0rem;
  left: 95rem;
  width: 151rem;
  height: 27rem;
  color: #5A4B41;
  font-size: 14rem;
  background-color: white;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
#splash .installHints .osSelectWrapper .android.opaque{
  background-color: transparent;
}
#splash .installHints img {
  width: 78%;
  height: 100%;
  object-fit: contain;
}
#splash .permissions label {
  display: block;
  margin-bottom: 16rem;
  margin-left: 36rem;
}
#splash .permissions .question {
  font-size: 18rem;
  font-weight: 700;
  text-align: center;
  position: absolute;
  bottom: 50rem;
  height: 150rem;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50rem;
  right: 50rem;
  line-height: 36rem;
}
#splash .permissions .question .siteUrl {
  display: inline-block;
  white-space: nowrap;
}
#splash .permissions .warning {
  font-size: 16rem;
  font-weight: 400;
  text-align: center;
  position: absolute;
  bottom: 50rem;
  height: 150rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 238rem;
  line-height: 1.4;
}
#splash .debugButton {
  position: absolute;
  right: 10rem;
  bottom: 10rem;
}
@media screen and (orientation: landscape) {
  #splash .installHints, 
  #splash .permissions {
    font-size: 10rem;
  }
  
}
@media screen and (max-width:380px) {
  #splash .installHints {
    padding-top: 17rem;
  }
}