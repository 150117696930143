#tiltGame {
  position: relative;
  padding-top: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #ccc;
}
#tiltGame .finalReading,
#tiltGame .currentReading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 50%;
}
#tiltGame .currentReading {
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border: 1px solid #000;
}
#tiltGame .finalReading:after,
#tiltGame .currentReading:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 1px solid #000;
  height: 100%;
}
#tiltGame .finalReading:before,
#tiltGame .currentReading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  border-top: 1px solid #000;
  width: 100%;
}
#tiltGame .finalReading.complete {
  background-color: #248f24;
}