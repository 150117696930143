#answerInput {
  height: 100%;
  display: flex;
  align-items: center;
}
#answerInput #musicPlayer {
  width: 100%;
}
#answerInput .answerArea {
  width: 375rem;
  margin: 0 auto;
  padding: 15rem;
}
#answerInput .answerPaper {
  position: relative;
  padding: 35rem 25rem;
  z-index: 1;
}
#answerInput .answerPaper > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
#answerInput .answerPaper .label {
  margin-bottom: 40rem;
  font-size: 20rem;
  color: #5A4B41;
}
#answerInput .header {
  color: #5A4B41;
  text-align: center;
  margin-bottom: 20rem;
  font-weight: 700;
  width: 245rem;
  margin: 0 auto;
}
#answerInput .header span {
  font-weight: 400;
  font-size: 14rem;
}
#answerInput .answerArea.cp6 .header,
#answerInput .answerArea.cp7 .header,
#answerInput .answerArea.cp8 .header {
  margin-bottom: 30rem;
}
#answerInput .inputGroup {
  display: flex;
  margin-right: -5rem;
}
#answerInput .reviewInput {
  position: relative;
  margin-top: 15rem;
  padding-bottom: 5rem;
  margin-bottom: 5rem;
}
#answerInput textarea {
  background-color: transparent;
  border: 1rem solid #B99D7E;
  width: 100%;
  font-size: 22rem;
  resize: none;
  padding: 10rem;
}
#answerInput .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  color: #B99D7E;
  padding: 10rem;
  font-size: 22rem;
  pointer-events: none;
}
#answerInput .reviewInput .wordCount {
  position: absolute;
  right: 0;
  top: 100%;
}
#answerInput .label {
  text-align: center;
  position: relative;
  height: 2.5em;
  margin-bottom: 10rem;
  font-size: 16rem;
}
#answerInput .inputBox {
  border: 1rem solid #B99D7E;
  border-radius: 8rem;
  text-align: center;
  position: relative;
  height: 2.5em;
  margin-bottom: 10rem;
}
#answerInput .inputBox.hasError {
  border-color: #ED6658;
}
#answerInput .inputGroup .inputBox {
  margin-right: 5rem;
}
#answerInput .inputBox input {
  outline: 0;
  appearance: none;
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
}
#answerInput .inputBox .displayAnswer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  color: #5A4B41;
  font-family: CaptureIt, NotoSans, sans-serif;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
}
#answerInput .inputBox.hasError .displayAnswer {
  color: #ED6658;
}
#answerInput .inputBox .displayAnswer .inputCross {
  position: absolute;
  right: 5rem;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}
#answerInput .inputBox.hasError .displayAnswer .inputCross {
  /* display: block; */
}
#answerInput .inputBox.hasError .displayAnswer .inputCross img {
  vertical-align: top;
}
#answerInput .inputBox .displayAnswer span {
  display: inline-block;
  /* width: 1em; */
  padding: 0 0.1em;
  text-align: center;
}
#answerInput .inputBox .displayAnswer.empty {
  color: #D6B77A;
  font-size: 18rem;
}
#answerInput .divider {
  margin-top: 20rem;
}
#answerInput .divider img {
  width: 100%;
  vertical-align: top;
}
#answerInput .helpText {
  font-size: 16rem;
  color: #5A4B41;
  text-align: center;
  margin-top: 15rem;
}
#answerInput .submitAnswer {
  color: #FFFFFF;
  width: 150rem;
  margin: 15rem auto;
}
#answerInput .submitAnswer.disabled {
  opacity: 0.7;
}
#answerInput .hintsButton {
  position: fixed;
  top: 50rem;
  left: 15rem;
  z-index: 2;
}
#answerInput .hintsButton img {
  vertical-align: top;
}
#answerInput .hintsButton .text {
  color: #5A4B41;
  font-size: 14rem;
  text-align: center;
  margin-top: 5rem;
  font-weight: 400;
}
#answerInput .hintsButton .hintsArrow {
  position: absolute;
  left: 100%;
  top: 0;
  margin: 5rem 0 0 10rem;
  
}
#answerInput .helpPanel .hintsButton .text {
  color: #FFFFFF;
}
#answerInput .helpPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000cc;
  z-index: 2;
  color: #FFFFFF;
  visibility: hidden;
}
#answerInput .showHelp .helpPanel {
  visibility: visible;
}

#answerInput .helpPanel .contentWrapper {
  width: 375rem;
  display: flex;
  flex-direction: column;
  /* padding: 0 25rem; */
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
#answerInput .helpPanel .header {
  font-size: 36rem;
  font-weight: 700;
  color: #FFFFFF;
}
#answerInput .helpPanel .seperator {
  padding: 5rem 0 30rem;
}
#answerInput .helpPanel .details {
  width: 100%;
  font-size: 18rem;
  padding: 0 35rem;
  text-align: center;
  overflow: hidden;
}
#answerInput .helpPanel .details span {
  color: #ED6658;
}
#answerInput .helpPanel .nextStageButton {
  width: 160rem;
  color: #FFFFFF;
  position: absolute;
  bottom: 50rem;
  left: 50%;
  transform: translateX(-50%);
}


#answerInput .envelope {
  margin-bottom: -50rem;
  text-align: right;
}
#answerInput .envelope img {
  margin-right: -50rem;
}
#answerInput .answerArea.cp8 .answerPaper {
  margin-top: 50rem;
}

#answerInput .answered {
  width: 375rem;
  margin: 0 auto;
  padding: 15rem;
  text-align: center;
  font-size: 28rem;
  color: #5A4B41;
  position: relative;
}
#answerInput .answered .winTick {
  margin-bottom: 25rem;
}
#answerInput .answered .text {
  position: relative;
  z-index: 1;
}
#answerInput .answered .winCharacter {
  position: absolute;
  top: 100%;
  left: 0;
  transform: translate(-25%, -25%) scale(-1, 1);
}
#answerInput .answered .winCharacter img {
  transform: translate(100%, 100%);
  transition: transform 0.4s;
}
#answerInput .answered .winCharacter.ready img {
  transform: translate(0%, 0%);
}
@media screen and (max-width: 320px) {
  
  #answerInput .hintsButton {
    top: 30rem;
  }
  #answerInput .envelope img {
    width: 150rem;
    margin-right: -30rem;
  }
  #answerInput .answered .winCharacter {
    transform: translate(-25%, -25%) scale(-0.8, 0.8);
  }
}