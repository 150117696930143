#hintsInput .content {
  position: fixed;
  z-index: 10;
  background-color: #FFFFFF;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#hintsInput .badge {
  position: fixed;
  left: 60rem;
  top: 50rem;
  background-color: #ED6658;
  z-index: 10;
  color: #FFFFFF;
  width: 20rem;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12rem;
  border-radius: 50%;
}

#hintsInput .topPanel {
  font-size: 28rem;
  height: 180rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* padding-top: 30rem; */
  margin-bottom: -15rem;
}
#hintsInput .backButton {
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translateY(-50%) rotate(180DEG);
  z-index: 2;
}
#hintsInput .backButton img {
  vertical-align: top;
}

#hintsInput .hintsIcon {
  position: absolute;
  right: 0rem;
  top: 50%;
  transform: translateY(-50%);
}
#hintsInput .contentWrapper {
  width: 375rem;
  display: flex;
  flex-direction: column;
  /* padding: 0 25rem; */
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  /* bottom: 0; */
  /* width: 100%; */
  justify-content: center;
  /* align-items: center; */
  /* margin: 0 auto; */
  padding-bottom: 120rem;
}
#hintsInput .header {
  font-size: 28rem;
  font-weight: 700;
  width: 300rem;
  margin-top: 30rem;
  position: relative;
  z-index: 1;
}
#hintsInput .helpText {
  font-size: 16rem;
  color: #5A4B41;
  text-align: center;
  margin-top: 15rem;
  margin-bottom: -15rem;
  font-weight: 400;
}
#hintsInput .hintsPaper {
  position: relative;
  z-index: 1;
  display: inline-block;
  align-self: center;
}
#hintsInput .hintsPaper .hintButtons,
#hintsInput .hintsPaper .hintDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFFFF;
  font-size: 24rem;
  line-height: 1.6;
}
#hintsInput .hintsPaper .hintDetails {
  width: 80%;
}
#hintsInput .hintsPaper .hintButton {
  margin: 20rem auto;
  position: relative;
  filter: contrast(0.6);
}
#hintsInput .hintsPaper .hintButton.active {
  filter: none;
}
#hintsInput .hintsPaper .hintButton .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #5A4B41;
}
#hintsInput .hintsPaper .hintButton .lockIcon {
  position: absolute;
  top: 50%;
  left: 25rem;
  transform: translateY(-50%);
}
#hintsInput .seperator {
  padding: 5rem 0 30rem;
}
#hintsInput .details {
  width: 100%;
  font-size: 18rem;
  padding: 0 35rem;
  text-align: center;
  overflow: hidden;
}
#hintsInput .details span {
  color: #ED6658;
}

#hintsInput .nextStageButton {
  width: 160rem;
  color: #FFFFFF;
  position: absolute;
  bottom: 50rem;
  left: 50%;
  transform: translateX(-50%);
}
#hintsInput .inputBox {
  border: 0;
}
#hintsInput .inputBox .displayAnswer {
  color: #FFFFFF;
  align-items: flex-end;
}
#hintsInput .hintsPaper .label {
  color: #FFFFFF;
  white-space: nowrap;
  font-size: 14rem;
  margin-top: 20rem;
  /* opacity: 0.6; */
}
#hintsInput .inputBox .displayAnswer .placeholder {
  color: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  font-size: 14rem;
  display: none;
  opacity: 0.6;
}
#hintsInput .inputBox .displayAnswer.empty .placeholder {
  display: block;
}
#hintsInput .inputBox .displayAnswer .answerPlaceholder {
  border-bottom: 1rem solid #FFFFFF;
  width: 34rem;
  margin: 0 4rem;
}
#hintsInput .inputBox .displayAnswer .userAnswerWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
#hintsInput .inputBox .displayAnswer .userAnswerWrapper .userAnswer {
  width: 34rem;
  margin: 0 4rem;
}
#hintsInput .hintsPaper .errorHelpText {
  position: absolute;
  bottom: 25rem;
  color: #ED6658;
  font-size: 14rem;
  left: 0;
  right: 0;
  text-align: center;
}