#sideTask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #5A4B41;
  overflow: hidden;
}
#sideTask.mainCharacter {
  color: #D6B77A;
}
#sideTask video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
#sideTask .targetMask {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250rem;
  height: 250rem;
  box-shadow: 0 0 0rem 500rem #00000080;
  transform: translate(-50%, -50%);
}
#sideTask .targetMask .uiImage {
  width: 40%;
  height: 40%;
  margin: 30%;
}
#sideTask .targetMask .uiImage img {
  width: 100%;
  height: 100%;
}
#sideTask .maskHints {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 150rem;
  text-align: center;
  margin-bottom: 140rem;
  color: #FFFFFF;
  transform: translate(-50%, 0);
}
#sideTask .backButton {
  position: absolute;
  bottom: 50rem;
  left: 0;
  right: 0;
  width: 160rem;
  color: #FFF;
  margin: 0 auto;
}
#sideTask.notTracking .loadingNFT,
#sideTask.notTracking video,
#sideTask.notTracking .targetMask,
#sideTask.notTracking .maskHints,
#sideTask.notTracking .backButton {
  opacity: 0;
}
#sideTask #backpack .backButton {
  opacity: 1;
  width: auto;
  right: auto;
}
#sideTask.mainCharacter .backgroundDarkener {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#sideTask .welcomeMessage,
#sideTask .foodSelection,
#sideTask .gamePass {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
#sideTask.mainCharacter .welcomeMessage,
#sideTask.mainCharacter .foodSelection,
#sideTask.mainCharacter .gamePass {
  background-color: rgba(0, 0, 0, 0.5);
}
#sideTask .welcomeMessage .screenWrapper .header {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-size: 36rem;
  font-weight: 700;
}
#sideTask .welcomeMessage .screenWrapper .brief {
  margin-top: 20rem;
}
#sideTask .welcomeMessage .screenWrapper .processButton {
  margin-top: 30rem;
}
#sideTask.mainCharacter  .foodSelection .foodWrapper {
  margin-top: 15rem;
}
#sideTask.mainCharacter  .foodSelection .foodWrapper img {
  height: 100rem;
}
#sideTask.mainCharacter  .foodSelection .foodFoundTick,
#sideTask.mainCharacter  .foodSelection .foodFoundTick img {
  height: 40rem;
}
#sideTask.mainCharacter  .foodSelection .foodWrapper .getFoodButton {
  border: 1rem solid #FFFFFF;
  color: #FFFFFF;
  display: inline-block;
  padding: 5rem 15rem;
  border-radius: 100rem;
}
#sideTask .gamePass .winText {
  font-size: 28rem;
  margin-top: 30rem;
}
#sideTask .viewBackpackButton {
  position: absolute;
  top: 30rem;
  left: 20rem;
  z-index: 1;
}
#sideTask .backpackArrowBrown {
  position: absolute;
  top: 40rem;
  left: 90rem;
  z-index: 1;
}

#sideTask .viewBackpackButton .unreadCounter {
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: 1;
  background-color: #ED6658;
  color: #FFFFFF;
  font-size: 12rem;
  width: 20rem;
  height: 20rem;
  line-height: 20rem;
  text-align: center;
  border-radius: 50%;
}
/* #sideTask.mainCharacter  .foodSelection .foodWrapper .foodTarget,
#sideTask.mainCharacter  .foodSelection .foodWrapper .foodTarget img {
  max-height: 100rem;
} */

@media screen and (max-width: 320px) {
  
  #sideTask.mainCharacter  .foodSelection .foodWrapper {
    margin-top: 25rem;
  }
  #sideTask.mainCharacter  .foodSelection .foodWrapper .foodTarget.food1 img {
    width: 161rem;
  }
  #sideTask.mainCharacter  .foodSelection .foodWrapper .foodTarget.food2 img {
    width: 98rem;
  }
  #sideTask.mainCharacter  .foodSelection .foodWrapper .foodTarget.food3 img {
    width: 134rem;
  }
}