#gathering {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#gathering .arrivedBox {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 300rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#gathering .arrivedBox svg {
  height: 100%;
}

#gathering .mapImage {
  position: absolute;
  top: 0;
  bottom: 285rem;
  width: 375rem;
  margin: 0 auto;
  z-index: 1;
}
#gathering .mapImage.mapBlur {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 285rem;
  z-index: 0;
}
#gathering .mapImage img {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
#gathering .mapImage.mapBlur img {
  z-index: 0;
  width: 110%;
  filter: blur(10rem);
}

#gathering .arrivedBox svg {
  height: 100%;
}
#gathering .arrivedBox .text {
  width: 250rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* gap: 35rem; */
  color: #5A4B41;
  text-align: center;
  padding-top: 50rem;
}
#gathering .gatherStamp {
  position: absolute;
  top: -20rem;
  left: 50%;
  margin-left: -180rem;
}
#gathering .gatherStamp,
#gathering .gatherStamp img {
  width: 120rem;
  /* margin: 0; */
  vertical-align: top;
}
#gathering .arrivedBox .text .hints {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 30rem;
}
#gathering .arrivedBox .text .characterImage {
  flex: 0 0 75rem;
  margin: 10rem 15rem;
}
#gathering .arrivedBox .text .characterImage img {
  width: 100%;
  vertical-align: top;
}

#gathering .characterImage {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
}
#gathering .characterImage.ready {
  opacity: 1;
  visibility: visible;
}
#gathering .debug {
  position: absolute;
  right: 10rem;
  bottom: 25rem;
}
#gathering .debugGpsData {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
@media screen and (orientation: landscape) {
  #gathering #gameTimer {
    top: 5rem;
    right: 15rem;
  }
  #gathering .backgroundWrapper {
    height: 100%;
    bottom: 0;
    left: 0;
    /* transform: translateX(-50%); */
    width: 50%;
  }
  #gathering .mapImage{
    width: calc(50% - 15rem);
    height: 100%;
    margin-left: 0;
  }
  #gathering .mapImage.mapBlur {
    width: 50%;
    height: 100%;
    margin-left: 0;
  }
  #gathering .stampWrapper {
    width: calc(50% - 15rem);
    bottom: 0;
    left: 0;
    transform: none;
  }
  #gathering .gatherStamp {
    top: 5rem;
    margin-left: -120rem;
  }
  #gathering .gatherStamp,
  #gathering .gatherStamp img {
    width: 70rem;
  }
  #gathering .stampWrapper .footprint.eagle {
    transform: translate(-50%, -50%) translate(-55rem, -55rem);
  }
  #gathering .stampWrapper .footprint.boar {
    transform: translate(-50%, -50%) translate(55rem, -55rem);
  }
  #gathering .stampWrapper .footprint.squirrel {
    transform: translate(-50%, -50%) translate(-55rem, 55rem);
  }
  #gathering .stampWrapper .footprint.fish {
    transform: translate(-50%, -50%) translate(55rem, 55rem);
  }
  #gathering .arrivedBox {
    height: 100%;
    width: 50%;
    right: 0;
    left: auto;
  }
  #gathering .arrivedBox:before {
    top: 45rem;
    left: 25rem;
    height: 30rem;
  }
  #gathering .arrivedBox:after {
    bottom: 0;
    left: 25rem;
    height: 30rem;
  }
  #gathering .arrivedBox .nameLabel {
    bottom: auto;
    top: 0;
    z-index: 3;
  }
  #gathering .arrivedBox > svg {
    top: auto;
    right: auto;
    left: 100%;
    bottom: 0;
    width: 100vh;
    height: calc(50vw + 15rem);
    transform: rotate(-90DEG);
    transform-origin: bottom left;
    position: absolute;
  }
  #gathering .arrivedBox .content {
    top: 45rem;
  }
  #gathering .arrivedBox .text {
    width: 180rem;
    /* gap: 20rem; */
  }
  #gathering .arrivedBox .text .characterImage {
    margin: 10rem;
    flex: 0 0 60rem;
  }
  #gathering .characterWrapper {
    bottom: 0;
    width: 50%;
    left: 0;
    transform: none;
  }
}