#musicPlayer {
  position: relative;
  padding: 15rem 0;
  touch-action: none;
}
#musicPlayer .playControl {
  margin-bottom: 25rem;
  text-align: center;
}
#musicPlayer .playControl img {
  width: 100rem;
}
#musicPlayer .progress {
  position: relative;
  padding: 5rem 15rem;
}
#musicPlayer .progress .progressBackground {
  position: absolute;
  background-color: #cdc5b1;
  top: 5rem;
  left: 15rem;
  right: 15rem;
  bottom: 5em;
  height: 5rem;
  border-radius: 5rem;
}
#musicPlayer .progress .currentProgress {
  position: relative;
  height: 5rem;
  background-color: #8e8671;
  border-radius: 5rem;
  width: 0;
}
#musicPlayer .progress .currentProgress .dragHandle {
  position: absolute;
  right: 0;
  width: 15rem;
  height: 15rem;
  background-color: #5A4B41;
  border-radius: 50%;
  top: -5rem;
  margin-right: -7.5rem;
}
#musicPlayer .currentTime {
  font-size: 14rem;
  /* margin-top: 10rem; */
  padding: 10rem 8rem;
}