#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#map .topPanel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 150rem;
  z-index: 1;
  color: #ffffff;
}
#map .topPanel svg {
  transform: rotate(180DEG);
  bottom: 0;
  top: auto;
}
#map .bottomPanel {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 210rem;
  z-index: 1;
  overflow: hidden;
}
#map .bottomPanel svg {
  height: 100%;
}
#map .topPanel svg {
  transform: rotate(180DEG);
  bottom: 0;
  top: auto;
}
#map .topPanel .text {
  font-size: 28rem;
  font-weight: 700;
}
#map .topPanel .mapIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#map .topPanel .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#map .topPanel .destDist {
  position: absolute;
  bottom: 0%;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
}
#map .topPanel .backButton {
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translateY(-50%) rotate(180DEG);
}
#map .mainSwiper {
  position: absolute;
  width: 100%;
  top: 135rem;
  bottom: 195rem;
  background-color: #fff;
  z-index: 0;
}
#map .mainSwiperBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#map .mainSwiperBackground .uiImage,
#map .mainSwiperBackground .uiImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#map .thumbsSwiper {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 15rem 0 50rem;
  overflow: hidden;
}
#map .thumbsSwiper .swiper-slide {
  display: flex;
  flex-direction: column;
}
#map .thumbsSwiper .pathBrief,
#map .thumbsSwiper .pathDetails {
  text-align: center;
  padding: 5rem 25rem 0;
}
#map .thumbsSwiper .pathBrief {
  font-size: 14rem;
}
#map .thumbsSwiper .pathBrief .title {
  padding-bottom: 5rem;
  color: #5A4B41;
  font-weight: 700;
}
#map .thumbsSwiper .pathDetails {
  overflow: auto;
  font-size: 18rem;
  padding-top: 10rem;
  color: #5A4B41;
  font-weight: 700;
}
#map .thumbsSwiper .pathDetails .arriveButton {
  height: 60rem;
}
#map .thumbsSwiper .pathDetails .arriveButton .text {
  color: #FFFFFF;
}
#map .thumbsSwiper .swiper-slide {
  position: relative;
}
#map .thumbsSwiper button.debug {
  position: absolute;
  bottom: 0rem;
  right: 10rem;
}
#map .swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
}
#map .swiper-slide {
  flex: 0 0 100%;
  height: 100%;
  overflow: hidden;
}
#map .swiper-pagination {
  position: absolute;
  bottom: 25rem;
  display: flex;
  /* gap: 15rem; */
  z-index: 2;
  left: 50%;
  transform: translate(-50%);
}
#map .swiper-pagination .swiper-pagination-bullet {
  width: 10rem;
  height: 10rem;
  background-color: #bda16f;
  display: inline-block;
  border-radius: 50%;
  margin: 0 7.5rem;
}
#map .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #5a4b41;
}
#map .mapWrapper,
#map .sceneWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#map .mapWrapper .uiImage {
  display: inline-block;
}
#map .mapWrapper .pinpoint {
  position: absolute;
  /* top: 22%;
  left: 30.4%; */
  animation: pinpoint 1.5s infinite;
  z-index: 1;
}
#map .mapWrapper .mapPath {
  position: absolute;
  /* top: 24.3%;
  left: 31.2%; */
}
#map .mapWrapper .mapPath img {
  vertical-align: top;
}
#map .mapWrapper .destination {
  position: absolute;
  /* top: 48.9%; */
  /* left: 55.8%; */
  /* animation: destination 1.5s infinite; */
}

#map .mapWrapper .pinpoint img {
  vertical-align: top;
}
#map .sceneWrapper {
  position: relative;
}
#map .sceneWrapper .mapImage,
#map .sceneWrapper .mapImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 1;
  position: relative;
}
#map .sceneWrapper .mapImage.blurImage {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  filter: blur(15rem);
}
#map .sceneWrapper .mapImage.blurImage img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
#map .sceneWrapper .characterImage {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-250rem, 0rem) scale(1);
  z-index: 2;
}
#map .sceneWrapper .characterImage img {
  transform: scaleX(-1);
}
#map .sceneWrapper .characterPop {
  position: absolute;
  left: 50%;
  bottom: 70rem;
  transform: translate(-75rem);
  z-index: 2;
  background-color: #ED6658;
  color: #FFFFFF;
  padding: 10rem 20rem;
  border-radius: 100rem;
  white-space: nowrap;
  font-size: 18rem;
}
#map .sceneWrapper .characterPop:before {
  content: '';
  border-right: 27rem solid #ED6658;
  border-top: 9rem solid transparent;
  border-bottom: 9rem solid transparent;
  position: absolute;
  left: -22rem;
  top: 50%;
  margin-top: -9rem;
}

#map .swiper-button-prev,
#map .swiper-button-next {
  position: absolute;
  top:  50%;
  width: 25rem;
  height: 40rem;
  transform: translateY(-50%);
  z-index: 2;
}
#map .swiper-button-prev {
  left: 15rem;
}
#map .swiper-button-next {
  right: 15rem;
}
#map .swiper-button-disabled {
  opacity: 0.2;
}
#map .swiper-button-prev:before,
#map .swiper-button-next:before {
  content:  '';
  display: block;
  border-top: 20rem solid transparent;
  border-bottom: 20rem solid transparent;
  position: absolute;
}
#map .swiper-button-prev:before {
  border-right: 15rem solid #FFFFFF;
  left: 0;
}
#map .swiper-button-next:before {
  border-left: 15rem solid #FFFFFF;
  right: 0;
}
@media screen and (max-height: 736px) {
  #map .sceneWrapper .characterImage {
    transform: translate(-250rem, 50rem) scale(0.6);
  }
  #map .sceneWrapper .characterPop {
    bottom: 30rem;
    transform: translate(-100rem) scale(0.8);
  }  
}
@media screen and (orientation: landscape) {
  #map .topPanel {
    width: 20%;
    height: 100%;
  }
  #map .topPanel svg {
    transform: rotate(90DEG);
    width: 100vh;
    height: 20vw;
    top: 100%;
    bottom: auto;
    left: auto;
    right: 0;
    transform-origin: top right;
  }
  #map .topPanel .text {
    font-size: 15rem;
    writing-mode: vertical-lr;
    letter-spacing: 0.2em;
  }
  #map .topPanel .mapIcon {
    top: 0;
    transform: none;
  }
  #map .topPanel #gameTimer {
    top: auto;
    bottom: 5rem;
    right: 80vw;
    left: 0;
  }
  #map .mainSwiper {
    top: 0;
    left: calc(20% - 5rem);
    right: calc(35% - 5rem);
    bottom: 0;
    width: auto;
  }
  #map .sceneWrapper .characterImage {
    transform: translate(-110rem, 20rem);
  }

  #map .sceneWrapper .characterImage,#map .sceneWrapper .characterImage img {
    width: 70rem;
  }

  #map .sceneWrapper .characterPop {
    font-size: 9rem;
    padding: 5rem 10rem;
    transform: translate(-20rem);
    bottom: 20rem;
  }

  #map .sceneWrapper .characterPop:before {
    border-right-width: 15rem;
    border-top-width: 5rem;
    border-bottom-width: 5rem;
    left: -12rem;
    margin-top: -5rem;
  }
  #map .bottomPanel {
    left: auto;
    right: 0;
    bottom: 0;
    width: 35%;
    height: 100%;
    padding: 0 15rem 0;
  }
  #map .bottomPanel svg {
    transform: rotate(-90DEG);
    width: 100vh;
    height: 35vw;
    top: 100%;
    bottom: auto;
    left: 0;
    transform-origin: top left;
  }
  #map .thumbsSwiper {
    width: auto;
    height: 100%;
    left: 10rem;
    right: 5rem;
  }
  #map .thumbsSwiper .pathBrief {
    padding: 5rem;
    font-size: 10rem;
  }
  #map .thumbsSwiper .pathDetails {
    font-size: 8rem;
    padding: 10rem 0rem 0;
  }
  #map .swiper-pagination .swiper-pagination-bullet {
    width: 5rem;
    height: 5rem;
    margin: 0 2.5rem;
  }
  #map .swiper-button-prev,
  #map .swiper-button-next {
    transform: none;
    width: 25rem;
    height: 40rem;
  }
  #map .swiper-button-prev {
    left: 0;
  }
  #map .swiper-button-next {
    right: 0rem;
  }
  #map .swiper-button-prev:before,
  #map .swiper-button-next:before {
    border-top: 15rem solid transparent;
    border-bottom: 15rem solid transparent;
    position: absolute;
  }
  #map .swiper-button-prev:before {
    border-right: 10rem solid #FFFFFF;
    left: 0;
  }
  #map .swiper-button-next:before {
    border-left: 10rem solid #FFFFFF;
    right: 0;
  }
}

@keyframes pinpoint {
  0%, 100%  {margin: -10rem 0 0 0;}
  50% {margin: -15rem 0 0 0;}
}
@keyframes destination {
  0%, 100%  {filter: drop-shadow(0 0 10rem #ec6557);}
  50% {filter: drop-shadow(0 0 25rem #ec6557);}
}