#story {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
#story .backgroundWrapper {
  height: auto;
  bottom: 190rem;
  width: 375rem;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#story .backgroundWrapper .sceneBackgroundImage img {
  width: 375rem;
  z-index: 2;
  right: 0;
  margin: 0 auto;
}
#story .backgroundWrapper .sceneBackgroundImage.blur img {
  width: 100%;
  filter: blur(15rem);
  z-index: 1;
}
#story .backgroundWrapper img {
  /* min-height: 100%;
  min-width: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
#story .storyTalkBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200rem;
}
#story .storyTalkBox:before {
  content: '';
  position: absolute;
  width: 100%;
  background-image: linear-gradient(#efefe8, #efefe800);
  height: 15rem;
  top: 14rem;
  z-index: 2;
  left: 0;
  pointer-events: none;
}
#story .storyTalkBox:after {
  content: '';
  position: absolute;
  width: 100%;
  background-image: linear-gradient(#efefe800, #efefe8);
  height: 15rem;
  bottom: env(safe-area-inset-bottom, 15rem);
  z-index: 2;
  left: 0;
  pointer-events: none;
}
#story .storyTalkBox svg {
  position: relative;
  vertical-align: top;
}
#story .storyTalkBox .content {
  position: absolute;
  top: 15rem;
  width: 100%;
  bottom: env(safe-area-inset-bottom, 15rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  padding: 15rem 0 30rem;
}
#story .storyTalkBox .nameLabel {
  position: absolute;
  left: 25rem;
  bottom: 100%;
  margin-bottom: -15rem;
  /* height: 45rem; */
}
#story .storyTalkBox .nameLabel svg {
  height: 100%;
  width: 100%;
  position: absolute;
}
#story .storyTalkBox .nameLabel .text {
  position: relative;
  padding: 8rem 15rem;
  color: #FFFFFF;
}
#story .storyTalkBox .content .textScrollWrapper {
  display: flex;
  align-items: center;
  min-height: 100%;
  padding: 0 125rem 0 25rem;
}
#story .storyTalkBox .content .textScrollWrapper .text {
  min-height: 4.2em;
  font-size: 18rem;
  color: #5A4B41;
  font-weight: 500;
}
#story .storyTalkBox .content .textScrollWrapper span {
  color: #ED6658;
}
#story .storyTalkBox .processButton {
  position: absolute;
  right: 25rem;
  top: 50%;
  width: 54rem;
  height: 55rem;
  margin-top: -28rem;
}
#story .storyTalkBox .processButton svg {
  width: 100%;
}
#story .characterWrapper {
  width: 375rem;
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 200rem;
  transform: translateX(-50%);
}
#story .characterWrapper .characterImage {
  position: absolute;
  bottom: 220rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) scale(1.4);
  transform-origin: bottom;
  transition: all 0.4s;
  opacity: 0;
  /* height: 400rem; */
}
#story .characterImage.ready {
  opacity: 1;
}
#story .characterImage.inactive {
  filter: grayscale(0.1) brightness(0.5);
}
#story .characterImage img {
  transition: all 0.4s;
  /* height: 100%; */
}
#story .characterImage img.flipped {
  transform: scaleX(-1);
}
#story .viewBackpackButton {
  position: absolute;
  top: 30rem;
  left: 20rem;
  z-index: 1;
}
#story .viewBackpackButton .unreadCounter {
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: 1;
  background-color: #ED6658;
  color: #FFFFFF;
  font-size: 12rem;
  width: 20rem;
  height: 20rem;
  line-height: 20rem;
  text-align: center;
  border-radius: 50%;
}
#story .viewHistoryButton {
  position: absolute;
  top: 100rem;
  left: 20rem;
  z-index: 1;
}
@media screen and (max-height: 736px) {
  #story .characterWrapper .characterImage {
    transform: translate(-50%, 20rem) scale(1.2);
  }
}
@media screen and (orientation: landscape) {
  #story #gameTimer {
    top: 5rem;
    right: 15rem;
  }
  #story .backgroundWrapper {
    height: 100%;
    bottom: 0;
    left: 0;
    right: auto;
    width: 50%;
  }
  #story .backgroundWrapper .sceneBackgroundImage img {
    width: 100%;
  }
  #story .storyTalkBox {
    height: 100%;
    width: 50%;
    right: 0;
    left: auto;
  }
  #story .storyTalkBox:before {
    top: 45rem;
    left: 25rem;
    height: 30rem;
  }
  #story .storyTalkBox:after {
    bottom: 0;
    left: 25rem;
    height: 30rem;
  }
  #story .storyTalkBox .nameLabel {
    bottom: auto;
    top: 0;
    left: 0;
    z-index: 3;
  }
  #story .storyTalkBox .nameLabel .text {
    font-size: 12rem;
    padding: 5rem 10rem;
  }
  #story .storyTalkBox > svg {
    top: auto;
    right: auto;
    left: 100%;
    bottom: 0;
    width: 100vh;
    height: calc(50vw + 15rem);
    transform: rotate(-90DEG);
    transform-origin: bottom left;
    position: absolute;
  }
  #story .storyTalkBox .content {
    top: 45rem;
  }
  #story .storyTalkBox .content .textScrollWrapper {
    padding: 0 10rem 0 0;
  }
  #story .storyTalkBox .content .textScrollWrapper .text {
    font-size: 12rem;
  }
  #story .characterWrapper {
    bottom: 0;
    width: 50%;
    left: 0;
    transform: none;
  }
  #story .characterImage {
    /* transform: translateX(-50%) scale(1); */
  }
  #story .storyTalkBox .processButton {
    top: auto;
    right: 0;
    bottom: 0;
  }
}