#cp3Game1 {
  position: relative;
  /* padding-top: 200px; */
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  background-color: #ccc;
}
#cp3Game1 .finalReading,
#cp3Game1 .currentReading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 375rem;
  height: 375rem;
  transform: translate(-50%, -50%);
  /* overflow: hidden; */
}
#cp3Game1 .currentReading {
  top: 50%;
  left: 50%;
  width: 150rem;
  height: 150rem;
  border-radius: 50%;
  border: 1px dashed #000;
  z-index: 1;
}
#cp3Game1 .finalReading:after,
#cp3Game1 .currentReading:after {
  content: "";
  position: absolute;
  top: -30%;
  left: 50%;
  border-left: 1px dashed #000;
  height: 160%;
}
#cp3Game1 .finalReading:before,
#cp3Game1 .currentReading:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -30%;
  border-top: 1px dashed #000;
  width: 160%;
}
#cp3Game1 .finalReading {
  overflow: visible;
}
#cp3Game1 .finalReading:after {
  top: 30%;
  height: 40%;
  border-left-style: solid;
}
#cp3Game1 .finalReading:before {
  left: 30%;
  width: 40%;
  border-top-style: solid;
}
#cp3Game1.complete {
  background-color: #248f24;
}
#cp3Game1 .actionHints {
  position: absolute;
  bottom: 20rem;
  color: #FFF;
  width: 200rem;
  font-size: 18rem;
  left: 50%;
  transform: translateX(-50%);
}