#chatHistory {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 100;
}
#chatHistory .backgroundWrapper {
  bottom: 0;
}
#chatHistory .topPanel {
  color: #5A4B41;
  font-size: 28rem;
  height: 150rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#chatHistory .topPanel svg {
  transform: rotate(180DEG);
  top: auto;
  bottom: 0;
}
#chatHistory .chatContent {
  position: absolute;
  top: 140rem;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  padding: 20rem;
}
#chatHistory .topPanel .backButton {
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translateY(-50%) rotate(180DEG);
}
#chatHistory .topPanel .chatHistoryIcon {
  position: absolute;
  top: 50%;
  right: 20rem;
  transform: translateY(-50%);
}
#chatHistory .topPanel .chatHistoryIcon img {
  vertical-align: top;
}
#chatHistory .topPanel .text {
  position: relative;
  z-index: 1;
}
#chatHistory .topPanel .dividerBrown {
  position: absolute;
  bottom: 0;
  left: 20rem;
}
#chatHistory .topPanel .dividerBrown img {
  vertical-align: bottom;
}
#chatHistory .characterIcon {
  width: 75rem;
  height: 75rem;
  display: inline-block;
  background-color: #F1F1EB;
  /* flex: 0 0 75rem; */
  border-radius: 50%;
  /* overflow: hidden; */
  /* line-height: 75rem; */
  text-align: center;
  position: relative;
  /* margin-bottom: 10rem; */
  /* background-color: red; */
}
#chatHistory .characterIcon.empty {
  background-color: transparent;
}
#chatHistory .characterIcon .characterImage,
#chatHistory .characterIcon .characterImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
#chatHistory .chatBubble {
  display: flex;
  margin-bottom: 10rem;
  align-items: center;
  /* opacity: 0.6; */
}
#chatHistory .chatBubble.active {
  /* opacity: 1; */
}

#chatHistory .chatDetails .character {
  font-size: 0.8em;
}

#chatHistory .chatDetails {
  margin-left: 10rem;
  flex: 1;
}
#chatHistory .chatDetails .character {
  padding: 5rem 10rem;
}
#chatHistory .chatDetails .text {
  background-color: #e8e6df;
  color: #5A4B41;
  flex: 1;
  padding: 10rem 10rem;
  font-size: 18rem;
}
#chatHistory .chatDetails.location .text {
  background-color: #5A4B41;
  color: #e8e6df;
}