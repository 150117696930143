#arCamera {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: red; */
  overflow: hidden;
}
#arCamera .loadingNFT {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
#arCamera video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
#arCamera .targetMask {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50vw;
  height: 50vw;
  /* z-index: 2; */
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 50vmax rgba(0,0,0, 0.5);

}
#arCamera .maskHints {
  position: absolute;
  top: 0;
  bottom: calc(50vh + 25vw);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}
#arCamera.notTracking .loadingNFT,
#arCamera.notTracking video,
#arCamera.notTracking .targetMask,
#arCamera.notTracking .maskHints {
  opacity: 0;
}
#arCamera .welcomeMessage,
#arCamera .characterSelect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25rem;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
#arCamera .welcomeMessage .screenWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}
#arCamera .welcomeMessage .header {
  font-size: 36rem;
  margin-bottom: 5rem;
  font-weight: bold;
}
#arCamera .welcomeMessage .seprator,
#arCamera .welcomeMessage .seprator img {
  width: 100%;
}
#arCamera .welcomeMessage .seprator {
  margin-bottom: 30rem;
}
#arCamera .welcomeMessage .brief {
  padding: 0 25rem;
  font-size: 21rem;
  margin-bottom: 50rem;
}
#arCamera .welcomeMessage .nextStep {
  width: 160rem;
  margin: 0 auto;
}
#arCamera .welcomeMessage .nextStep .text {
  /* fix for sachu iphone 11 pro */
  white-space: nowrap;
}
#arCamera .characterSelect .name {
  font-size: 36rem;
  margin-bottom: 5rem;
  font-weight: bold;
}
#arCamera .characterSelect .characterImage {
  padding-top: 125%;
  position: relative;
  margin: 25rem 0;
}
#arCamera .characterSelect .characterImage img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
}
#arCamera .characterSelect .buttonWrapper {
  display: flex;
  gap: 10rem;
  justify-content: center;
}
#arCamera .characterSelect .buttonWrapper .svgButton {
  flex: 0 0 157rem;
}
#arCamera .characterSelect .errorMessage {
  font-size: 16rem;
  margin-bottom: 10rem;
  color: #ED6658;
  position: relative;
}
#arCamera .characterSelect .errorMessage .characterRepeat {
  position: absolute;
  right: 20rem;
  width: 30rem;
  top: 50%;
  transform: translateY(-50%);
}
#arCamera .characterSelect .errorMessage .characterRepeat img {
  width: 100%;
}