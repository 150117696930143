#selfie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#selfie canvas,
#selfie .videoWrapper {
  position: absolute;
  width: 363rem;
  /* padding-right: 6rem; */
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
}
#selfie video,
#selfie .focusHints {
  position: absolute;
  width: 306rem;
  height: 379rem;
  top: 53rem;
  left: 25rem;
  object-fit: cover;
  display: block;
  z-index: 0;
  display: none;
}
#selfie video.user {
  transform: scaleX(-1);
}
#selfie .focusHints {
  border-left: 83rem solid rgba(0, 0, 0, 0.3);
  border-right: 83rem solid rgba(0, 0, 0, 0.3);
  border-top: 100rem solid rgba(0, 0, 0, 0.3);
  border-bottom: 99rem solid rgba(0, 0, 0, 0.3);
  overflow: hidden;
  display: none;
}
#selfie .focusHints:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 100rem rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
#selfie .photoFrame {
  width: 100%;
  position: relative;
  z-index: 1;
  display: none;
}
#selfie .gameTitle {
  position: absolute;
  right: 40rem;
  top: 75rem;
  width: 60rem;
  z-index: 2;
  display: none;
}
#selfie .stamp {
  bottom: 20rem;
  left: 20rem;
  position: absolute;
  width: 125rem;
  z-index: 1;
  display: none;
}
#selfie .headband {
  position: absolute;
  height: 243rem;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: none;
}
#selfie .character {
  bottom: 20rem;
  right: 20rem;
  height: 190rem;
  position: absolute;
  z-index: 1;
  display: none;
}
#selfie .character img {
  height: 100%;
  vertical-align: top;
}
#selfie .gameTitle img,
#selfie .stamp img,
#selfie .photoFrame img {
  width: 100%;
  vertical-align: top;
}
#selfie .captureButton {
  position: absolute;
  left: 50%;
  bottom: 20rem;
  width: 75rem;
  height: 75rem;
  margin-left: -37.5rem;
  border-radius: 50%;
  /* background-color: #FF0000; */
  /* border: 2px solid #FFFFFF; */
  z-index: 1;
}
#selfie .captureButton img {
  width: 100%;
  vertical-align: top;
}
#selfie .captureButton.active {
  filter: brightness(0.9);
}
/* #selfie .captureButton:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #FFFFFF;
  transition: width 0.2s, height 0.2s;
}
#selfie .captureButton.active:after {
  width: 40px;
  height: 40px;
} */
#selfie .captureFlash {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
  z-index: 2;
}
#selfie .captureFlash.active {
  opacity: 1;
  transition: none;
  visibility: visible;
}
#selfie .capturedPhoto {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
#selfie .capturedPhoto > img {
  width: 363rem;
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -25%);
}
#selfie .capturedPhoto .backBtn {
  position: absolute;
  top: 40rem;
  left: 20rem;
  filter: drop-shadow(0rem 0rem 5rem #888888);
}
#selfie .capturedPhoto .buttonWrapper {
  position: absolute;
  bottom: 40rem;
  left: 20rem;
  right: 20rem;
  display: flex;
  gap: 20rem;
}
#selfie .capturedPhoto .svgButton {
  color: #FFFFFF;
}
#selfie .switchCameraButton {
  /* border-color: transparent white;
	border-radius: 50%;
	border-style: solid;
	border-width: 2px;
	height: 30px;
	margin: 5px;
	width: 30px; */
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-160rem, -115rem);
}
#selfie .aimHints {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
  color: #F1F1EB;
  background-color: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
}
#selfie .aimHintsText {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -155rem);
}
#selfie .aimArrow {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -80rem) rotate(-90DEG);
}
/* #selfie .switchCameraButton:before,
#selfie .switchCameraButton:after {
  border-style: solid;
  content: '';
  display: block;
  position: absolute;
  width: 0;
  transform: rotate(-45deg);
}
#selfie .switchCameraButton:after {
  border-color: transparent transparent transparent white;
  border-width: 6px 0 6px 10px;
  top: -6px;
  left: 1px;
}
#selfie .switchCameraButton:before {
  border-color: transparent white transparent transparent;
  border-width: 6px 10px 6px 0;
  bottom: -6px;
  right: 1px;
} */

#selfie .tutor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 25rem;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}
#selfie .tutor .screenWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* z-index: 1; */
}
#selfie .tutor .header {
  font-size: 36rem;
  margin-bottom: 5rem;
  font-weight: bold;
}
#selfie .tutor .separator,
#selfie .tutor .separator img {
  width: 100%;
}
#selfie .tutor .separator {
  margin-bottom: 30rem;
}
#selfie .tutor .brief {
  padding: 0 20rem;
  font-size: 21rem;
  margin-bottom: 50rem;
}
#selfie .tutor .nextStep {
  width: 160rem;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  /* position: absolute;
  bottom: 62rem;
  left: 109rem; */
}
#selfie .tutor .selfieCharacterImage{
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 0;
  z-index: 0;
}
#selfie .tutor .selfieCharacterImage img{
  height: 180rem;
  width: auto;
  /* margin: 0rem 0rem 0rem 141rem; */
}