#videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#videoPlayer .answerPaper {
  position: absolute;
  left: 15rem;
  right: 15rem;
  padding: 35rem 25rem;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
#videoPlayer .answerPaper > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#videoPlayer .answerPaper .contentWrapper {
  /* position: absolute;
  top: 0; */
}
#videoPlayer .answerPaper .contentWrapper .header {
  color: #5A4B41;
  text-align: center;
  margin-bottom: 20rem;
  font-weight: 700;
  width: 245rem;
  font-size: 30rem;
  margin: 0 auto;
}
#videoPlayer .answerPaper .contentWrapper .seperator img {
  width: 100%;
}
#videoPlayer .answerPaper .contentWrapper .details {
  color: #5A4B41;
  text-align: center;
  margin: 30rem 0 60rem;
}
#videoPlayer .answerPaper .contentWrapper .startVideoButton {
  color: #FFFFFF;
  width: 160rem;
  margin: 0 auto;
}
#videoPlayer .videoWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  transition: all 0.4s;
  opacity: 0;
  visibility: hidden;
}
#videoPlayer .videoWrapper.ready {
  transform: translate(-50%, -50%) rotate(90DEG);
  width: calc(100vh + 1px);
  height: calc(100vw + 1px);
  background-color: black;
  opacity: 1;
  visibility: visible;
}
#videoPlayer .videoWrapper video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
#videoPlayer .videoWrapper .replayPanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}
#videoPlayer .videoWrapper .replayPanel .buttonWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#videoPlayer .videoWrapper .replayPanel .svgButton {
  width: 200rem;
  margin: 10rem 0;
  color: #FFFFFF;
}
#videoPlayer .videoWrapper .replayButton {
  text-align: center;
}

#videoPlayer .loading-ring {
  display: block;
  position: absolute;
  width: 80rem;
  height: 80rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0,0,0,0.5);
  border-radius: 10rem;
}
#videoPlayer .loading-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64rem;
  height: 64rem;
  margin: 8rem;
  border: 8rem solid #fff;
  border-radius: 50%;
  animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
#videoPlayer .loading-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
#videoPlayer .loading-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
#videoPlayer .loading-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@media screen and (orientation: landscape) {
  #videoPlayer .videoWrapper.ready {
    transform: translate(-50%, -50%);
    width: 100vw;
    top: 50%;
    height: 100vh;
  }
  #videoPlayer .videoWrapper .replayPanel .svgButton {
    width: 120rem;
    font-size: 12rem;
  }
}
@keyframes loading-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}